import AgentLocationRepository from '../api/agentRegister';

class AgentLocationUseCase {
  constructor() {
    /** @type {AgentLocationRepository} */
    this.api = new AgentLocationRepository();
  }

  /**
     *
     * @param payload {Object}
     * @param payload.payload {RegisterAgentPayload}
     * @param payload.params {UTMRequest}
     * @return {Promise<{data: {RegisterAgentResponse}, err: ErrorResponse}>}
    */
  async registerAgent(payload) {
    const { data, err } = await this.api.registerAgent(payload);
    if (data) {
      return { data, err: null };
    }
    return { data: null, err };
  }

  /**
     *
     * @return {Promise<{data: Locations, err: String}>}
    */
  async getProvince() {
    const { data, err } = await this.api.getProvince();
    if (data.data) {
      return { data: data.data, err: null };
    }
    return { data: null, err };
  }

  /**
     *
     * @param province_id {Number}
     * @return {Promise<{data: Locations, err: String}>}
    */
  async getDistrict(province_id) {
    const { data, err } = await this.api.getDistrict(province_id);
    if (data.data) {
      return { data: data.data, err: null };
    }
    return { data: null, err };
  }

  /**
     *
     * @param disctrict_id {Number}
     * @return {Promise<{data: Locations, err: String}>}
    */
  async getSubDistrict(disctrict_id) {
    const { data, err } = await this.api.getSubDistrict(disctrict_id);
    if (data.data) {
      return { data: data.data, err: null };
    }
    return { data: null, err };
  }

  /**
     *
     * @param sub_disctrict_id {Number}
     * @return {Promise<{data: Locations, err: String}>}
    */
  async getVillage(sub_disctrict_id) {
    const { data, err } = await this.api.getVillage(sub_disctrict_id);
    if (data.data) {
      return { data: data.data, err: null };
    }
    return { data: null, err };
  }

  /**
      * @param payload {Object}
      * @param payload.agent_id {Number}
      * @param payload.files {Object}
      * @return {Promise<{data: {RegisterAgentResponse}, err: String}>}
     */
  async submitSupportDocument(payload) {
    const { data, err } = await this.api.submitSupportDocument(payload);
    if (data) {
      return { data, err: null };
    }
    return { data: null, err };
  }
}

export default new AgentLocationUseCase();
