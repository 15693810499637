import InternalAPI from '@/app/baseAxios';
import '../types';
import { getAgentCaptchaKey } from '@/misc/Cookies';

/** @borrows InternalAPI */
export default class AgentLocation extends InternalAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = '/v1/agent';
    this.location_endpoint = '/v1/location';
  }

  /**
     *
     * @param payload {Object}
     * @param payload.payload {RegisterAgentPayload}
     * @param payload.params {UTMRequest}
     * @return {Promise<{data: RegisterAgentResponse, err: ErrorResponse}>}
    */
  async registerAgent(payload) {
    try {
      const res = await this.$axios.post(this.endpoint, payload.payload, {
        params: payload.params,
        headers: {
          'Captcha-Key': getAgentCaptchaKey() || '',
        },
      });
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: res.data, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response.data.desc || err.response.data.message) };
    }
  }

  /**
     *
     * @return {Promise<{data: Locations, err: ErrorResponse}>}
    */
  async getProvince() {
    try {
      const res = await this.$axios.get(`${this.location_endpoint}/province/data?page=1&per_page=50`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: res.data, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response.data.message) };
    }
  }

  /**
     *
     * @param province_id {Number}
     * @return {Promise<{data: Locations, err: ErrorResponse}>}
    */
  async getDistrict(province_id) {
    try {
      const res = await this.$axios.get(`${this.location_endpoint}/district/data?page=1&per_page=100&province_id=${province_id}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: res.data, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response.data.message) };
    }
  }

  /**
     *
     * @param disctrict_id {Number}
     * @return {Promise<{data: Locations, err: ErrorResponse}>}
    */
  async getSubDistrict(disctrict_id) {
    try {
      const res = await this.$axios.get(`${this.location_endpoint}/subdistrict/data?page=1&per_page=100&district_id=${disctrict_id}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: res.data, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response.data.message) };
    }
  }

  /**
     *
     * @param sub_disctrict_id {Number}
     * @return {Promise<{data: Locations, err: ErrorResponse}>}
    */
  async getVillage(sub_disctrict_id) {
    try {
      const res = await this.$axios.get(`${this.location_endpoint}/village/data?page=1&per_page=100&sub_district_id=${sub_disctrict_id}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: res.data, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response.data.message) };
    }
  }

  /**
      * @param payload {Object}
      * @param payload.agent_id {Number}
      * @param payload.files {Object}
      * @return {Promise<{data: {RegisterAgentResponse}, err: ErrorResponse}>}
    */
  async submitSupportDocument(payload) {
    try {
      const res = await this.$axios.patch(`${this.endpoint}/${payload.agent_id}`, payload.files, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: res.data, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response.data.desc || err.response.data.message) };
    }
  }
}
