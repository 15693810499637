export default {
  data() {
    return {
      isErrorAlertOpen: false,
      errorAlertMessage: '',
      timout: null,
    };
  },
  methods: {
    openErrorAlert(errMsg = '') {
      clearTimeout(this.timeout);
      this.isErrorAlertOpen = true;
      this.errorAlertMessage = errMsg;
      if (this.isErrorAlertOpen) {
        this.timeout = setTimeout(() => {
          this.isErrorAlertOpen = false;
        }, 5000);
      }
    },
    closeErrorAlert() {
      this.isErrorAlertOpen = false;
      this.errorAlertMessage = '';
    },
  },
};
